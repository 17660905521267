<!--  -->
<template>
  <div class="contact-info-page">
    <div class="form-info">
      <van-cell class="title-box">
        <p class="title">联系人详情</p>
        <p class="link" @click="jump">编辑</p>
      </van-cell>
      <el-form
        :model="contactsForm"
        :rules="contactsRules"
        ref="contactsForm"
        label-position="top"
        class="form"
      >
        <el-form-item label="姓名" prop="userName">
          <el-input
            v-model.trim="contactsForm.userName"
            maxlength="20"
            placeholder="请输入姓名"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model.trim="contactsForm.email"
            placeholder="请输入邮箱"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="userPhone">
          <el-input
            v-model.trim="contactsForm.userPhone"
            maxlength="20"
            placeholder="请输入手机号"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人分组" prop="groupCode">
          <el-select
            disabled
            v-model="contactsForm.groupCode"
            placeholder="请选择分组"
          >
            <el-option
              v-for="(group, g) in groupData"
              :key="g"
              :label="group.name"
              :value="group.groupCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer-buttons">
      <van-button class="pass" @click="wirteEmail">写邮件</van-button>
      <van-button class="reject-btn" @click="handleDel">删除</van-button>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { groupList, getContactUserInfo, delContactUser } from "@/api/email";
export default {
  data() {
    const validPhone = (rule, value, callback) => {
      if (value) {
        const reg = /^(?:(?:\+|00)86)?1\d{10}$/;
        reg.test(value) ? callback() : callback(new Error("手机号格式不正确"));
        return;
      }
      return callback();
    };
    return {
      contactsForm: {
        bizCode: "",
        email: "",
        groupCode: "",
        userName: "",
        userPhone: "",
      },
      contactsRules: {
        email: [
          { required: true, message: "请输入邮件", trigger: "blur" },
          {
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        userPhone: [{ validator: validPhone, trigger: "blur" }],
        groupCode: [
          { required: true, message: "请选择分组", trigger: "change" },
        ],
      },
      groupData: [],
    };
  },

  components: {},

  computed: {
    id() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.getConcatInfo();
    this.getList();
  },

  methods: {
    getConcatInfo() {
      getContactUserInfo({ bizCode: this.id }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.contactsForm = data;
          }
        }
      });
    },
    //分组
    getList() {
      groupList().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.groupData = data;
          }
        }
      });
    },
    jump() {
      this.$router.push({
        path: "/addEmailContact",
        query: {
          id: this.id,
        },
      });
    },
    wirteEmail() {
      this.$router.push(`/EmailSend`);
    },
    handleDel() {
      Dialog.confirm({
        title: "提示",
        message: "此操作将删除联系人, 是否继续?",
      })
        .then(() => {
          // on confirm
          this.delUser(this.id);
        })
        .catch(() => {
          // on cancel
        });
    },
    // 删除联系人
    delUser(code) {
      delContactUser({ bizCode: code }).then((res) => {
        if (res) {
          let { success, msg } = res;
          if (success) {
            Toast.success(msg);
            this.$router.go(-1);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contact-info-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .form-info {
    flex: 1;
    .title-box {
      background: #f0f6ff;
      font-weight: 900;
      height: 0.8rem;

      .van-cell__value {
        display: flex;
        flex-direction: row;
        .title {
          text-align: center;
          flex: 1;
        }
        .link {
          width: 0.8rem;
          text-align: center;
        }
      }
    }
    .form {
      margin-top: 0.15rem;
      ::v-deep .el-form-item {
        margin-bottom: 0.2rem;
      }
      ::v-deep .el-form-item__label {
        width: 100%;
        background: #f0f0f0;
        padding: 0rem 0.2rem;
      }
      ::v-deep .el-form-item__content {
        padding: 0.2rem;
        .el-input__inner {
          border: none;
        }
        .el-select,
        .el-input {
          width: 100%;
        }
      }
    }
  }
  .footer-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 2.18rem;
      height: 0.8rem;
      background: #ffffff;
      border-radius: 0.4rem 0 0 0.4rem;
      font-size: 0.26rem;
      color: #ee0a24;
      line-height: 0.37rem;
    }

    .reject-btn {
      width: 2.18rem;
      height: 0.8rem;
      background: linear-gradient(to right, #ff6034, #ee0a24);
      border-radius: 0 0.4rem 0.4rem 0;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
      font-size: 0.26rem;
      line-height: 0.37rem;
    }
  }

  .link {
    color: #0094ee;
  }
}
</style>
